import React, {Fragment, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Avatar, Divider, Drawer, Hidden, Paper, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import useRouter from 'utils/useRouter';
import {Navigation} from 'components';
import navigationConfig from './navigationConfig';
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        backgroundColor: "#1b70b9"
    },
    content: {
        padding: theme.spacing(2)
    },
    profile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        color: "#fff",
        marginTop: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(2)
    },
    navigation: {
        marginTop: theme.spacing(2)
    }
}));

const NavBar = props => {
    const {openMobile, onMobileClose, className, ...rest} = props;
    const classes = useStyles();
    const router = useRouter();
    const { t } = useTranslation();
    const session = useSelector(state => state.session);

    useEffect(() => {
        if (openMobile) {
            onMobileClose && onMobileClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.location.pathname]);
    //console.log("session actuelle : ",session)
    const navbarContent = (
        <div className={classes.content}>
            <div className={classes.profile}>
                <Avatar
                    alt="Person"
                    className={classes.avatar}
                    // to="/profile/1/timeline"
                    src={session.user.avatar_url}
                  
                >{ session.user && session.user.lastname[0].toUpperCase()}</Avatar>
                <Typography
                    className={classes.name}
                    variant="h4"
                    style={{ textTransform : 'capitalize' }}
                >
                    {session.user &&  session.user.lastname} {!session.user && session.user.firstname}
                </Typography>
                <Typography
                    className={classes.name}
                    variant="h6"
                    style={{ }}
                >
                    {  session.user &&  session.user.email}
                </Typography>
            </div>
            <Divider className={classes.divider}/>
            <nav className={classes.navigation}>
                {navigationConfig.map(list => (
                    <Navigation
                        component="div"
                        key={list.title}
                        pages={list.pages}
                        title={t(list.title)}
                    />
                ))}
            </nav>
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    <div
                        {...rest}
                        className={clsx(classes.root, className)}
                    >
                        {navbarContent}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Paper
                    {...rest}
                    className={clsx(classes.root, className)}
                    elevation={1}
                    square
                >
                    {navbarContent}
                </Paper>
            </Hidden>
        </Fragment>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
