import {combineReducers} from 'redux';
import AuthReducer from "./AuthReducer";
import ListReducer from "./ListReducer";

const rootReducer = combineReducers({
    session: AuthReducer,
    lists : ListReducer
});

export default rootReducer;
