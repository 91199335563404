export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

export const login = (payload) => dispatch =>
    dispatch({
        type: SESSION_LOGIN,
        payload: payload
    });

export const logout = () => dispatch => 
    dispatch({
        type: SESSION_LOGOUT
    });
