import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Button} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {red} from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    button: {
        borderColor: props => props.error && red[500],
        color : props => props.error && red[500],
        '&:hover' : {
            borderColor: props => props.error && red[400],
        }

    },
    wrapper: {
        position: 'relative',
        display : 'inline'
        // width: props => props.fullWidth && '100%'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ButtonGradiantCustom = props => {
    const {className, customWrapper, loading, children, ...rest} = props;

    const classes = useStyles(props);

    return (
        <div className={clsx(classes.wrapper, customWrapper)}>
            <Button
                disabled={loading || props.disabled}
                {...rest}
                className={clsx(classes.button, className)}
            >
                {children}
                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </Button>
        </div>
    );
};

export default ButtonGradiantCustom;
