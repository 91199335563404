import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Paper, Button, Input, CircularProgress} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    search: {
        flexGrow: 1,
        height: 42,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.icon
    },
    searchInput: {
        flexGrow: 1
    },
    searchButton: {
        marginLeft: theme.spacing(2)
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: '#f9357c'
    },
}));

const Search = props => {
    const classes = useStyles();

    const {loading, onChange, withoutButton, value, onSearch, className, placeholder, userType, ...rest} = props;

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Paper
                className={classes.search}
                elevation={1}
            >
                <SearchIcon className={classes.searchIcon}/>
                <Input
                    className={classes.searchInput}
                    disableUnderline
                    placeholder={placeholder ? placeholder : 'Mot clé'}
                    onChange={(event) => onChange(event.target.value)}
                />
            </Paper>
            {
                !withoutButton &&
                <Box style={{position: 'relative'}}>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    <Button
                        className={classes.searchButton}
                        onClick={() => onSearch(true)}
                        size="large"
                        variant="contained"
                        disabled={value === '' || loading}
                    >
                        Rechercher
                    </Button>
                </Box>

            }
        </div>
    );
};

Search.propTypes = {
    className: PropTypes.string,
    onSearch: PropTypes.func
};

export default Search;
