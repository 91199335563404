let host_url =  global.location.host;
let host_var = "DEV";
 
if(host_url===process.env.REACT_APP_PROD_SERVER){ 
  host_var = "PROD";
}
else if(host_url===process.env.REACT_APP_DEV_SERVER){
  host_var = "DEV";
}

console.log({host_url, host_var,ENV:process.env.REACT_APP_DEV_SERVER});

export default {
  stripe_pub_key: process.env[`REACT_APP_${host_var}_STRIPE_PUB_KEY`],
  api_url: process.env[`REACT_APP_${host_var}_API_URL`]
};
