import {colors} from '@material-ui/core';
const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        contrastText: "#fff",
        main: "#1b70b9",
    },
    secondary: {
        contrastText: "#010101",
        main: "#ED2224",
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
    },
    text: {
        primary: "#010101",
        secondary: "#010101",
        link: "#f6eb16"
    },
    link: "#f6eb16",
    icon: colors.blueGrey[600],
    background: {
        default: '#F7F8F8',
        paper: "#F7F8F8"
    },
    divider: colors.grey[200]
};
