/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/home"/>
    },
    {
        path: '/auth',
        component: AuthLayout,
        routes: [
            {
                path: '/auth/login',
                exact: true,
                component: lazy(() => import('views/Login'))
            },
            // {
            //     path: '/auth/register',
            //     exact: true,
            //     component: lazy(() => import('views/Register'))
            // },
            {
                component: () => <Redirect to="/errors/error-404"/>
            }
        ]
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/home',
                exact: true,
                component: lazy(() => import('views/Dashboard'))
            },
            {
                path: '/requests',
                exact: true,
                component: lazy(() => import('views/Demandes'))
            },
            {
                path: '/requests/:id',
                exact: true,
                component: lazy(() => import('views/Demandes/components/Detail')),
                demande: {},
            },
            {
                path: '/presentations/testimony',
                exact: true,
                component: lazy(() => import('views/Testimony'))
            },
            {
                path: '/presentations/guidevoyage',
                exact: true,
                component: lazy(() => import('views/GuideVoyage'))
            },
            {
                path: '/messages',
                exact: true,
                component: lazy(() => import('views/Messages'))
            },
            {
                path: '/translators',
                exact: true,
                component: lazy(() => import('views/Translators'))
            },
            /*{
                path: '/presentations/faq-categories',
                exact: true,
                component: lazy(() => import('views/FaqCategory'))
            },
            {
                path: '/presentations/faq-questions',
                exact: true,
                component: lazy(() => import('views/FaqQuestions'))
            },*/
            {
                path: '/assurances',
                exact: true,
                component: lazy(() => import('views/Insurances'))
            },
            {
                path: '/studies/niveau',
                exact: true,
                component: lazy(() => import('views/StudiesLevels'))
            },
            {
                path: '/studies/semester',
                exact: true,
                component: lazy(() => import('views/Semester'))
            },
            {
                path: '/users',
                exact: true,
                component: lazy(() => import('views/Users'))
            },

            {
                path: '/studies/curriculum',
                exact: true,
                component: lazy(() => import('views/Cursus'))
            },
            {
                path: '/users/:id',
                exact: true,
                component: lazy(() => import('views/UserDetail'))
            },
            {
                path: '/studies/materials',
                exact: true,
                component: lazy(() => import('views/Matieres'))
            },
            {
                path: '/banks',
                exact: true,
                component: lazy(() => import('views/Banks'))
            },
            {
                path: '/studies/universities',
                exact: true,
                component: lazy(() => import('views/University'))
            },
            {
                path: '/studies/universities/:id',
                exact: true,
                component: lazy(() => import('views/University/components/Detail')),
                university: {}
            },
            /*{
                path :'/presentations/infos',
                exact : true,
                component : lazy(() => import('views/Info'))
            },*/
            {
                path :'/userprofile',
                exact:true,
                component: lazy(()=> import('views/UserProfile'))

            },
            {
                path :'/orders',
                exact:true,
                component: lazy(()=> import('views/Purchase'))
            },
            {
                path :'/products',
                exact : true,
                component : lazy(() => import('views/Products'))
            },
            {
                path :'/statistics/studies',
                exact:true,
                component: lazy(()=> import('views/StudiesStatistics'))
            },
            {
                path :'/statistics/services',
                exact : true,
                component : lazy(() => import('views/ServicesStatistics'))
            },
            {
                path :'/mail',
                exact : true,
                component : lazy(() => import('views/Mail'))
            }

        ]
    }
];

export default routes;
