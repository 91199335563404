import {
    AccountBalance,
    AccountCircle,
    AllInbox,
    Book,
    Business,
    ChromeReaderMode,
    Dashboard,
    DeveloperBoard,
    Dns,
    Equalizer,
    Euro,
    GTranslate,
    MailOutline,
    Person,
    School,
    Security,
    Send,
    Store,
    SupervisorAccount,
    ViewWeek
} from '@material-ui/icons'

export default [
    {
        title: '',
        pages: [
            {
                title: 'menu_item_dashboard',
                href: '/home',
                icon: Dashboard
            },
            {
                title: 'menu_item_requests',
                href: '/requests',
                icon: AllInbox
            },
            {
                title: 'menu_item_orders',
                href: '/orders',
                icon: Euro
            },
            {
                title: 'menu_item_services',
                href: '/services',
                icon: Store,
                children: [
                    {
                        title: 'menu_item_products',
                        href: '/products',
                        icon: DeveloperBoard
                    },
                    {
                        title: 'menu_item_banks',
                        href: '/banks',
                        icon: AccountBalance
                    },
                    {
                        title: 'menu_item_assurances',
                        href: '/assurances',
                        icon: Security
                    },
                    {
                        title: 'menu_item_translators',
                        href: '/translators',
                        icon: GTranslate
                    },
                ]
            },

            {
                title: 'menu_item_studies',
                href: '/studies',
                icon: School,
                children: [
                    {
                        title: 'menu_item_studies_university',
                        href: '/studies/universities',
                        icon: Business
                    },
                    {
                        title: 'menu_item_studies_materials',
                        href: '/studies/materials',
                        icon: Book
                    },
                    {
                        title: 'menu_item_studies_curriculum',
                        href: '/studies/curriculum',
                        icon: Dns
                    },

                    {
                        title: 'menu_item_studies_semester',
                        href: '/studies/semester',
                        icon: ViewWeek
                    },
                    {
                        title: 'menu_item_studies_niveaux_de_langues',
                        href: '/studies/niveau',
                        icon: GTranslate
                    },
                ]
            },
            {
                title: 'menu_item_stats',
                href: '/statistics',
                icon: Equalizer,
                children: [
                    {
                        title: 'menu_item_studies',
                        href: '/statistics/studies',
                        icon: School
                    },
                    {
                        title: 'menu_item_services',
                        href: '/statistics/services',
                        icon: Store
                    },
                ]
            },
            {
                title: 'menu_item_messages',
                href: '/messages',
                icon: MailOutline
            },
            {
                title: 'menu_item_users',
                href: '/users',
                icon: Person
            },
            {
                title: 'menu_item_presentations',
                href: '/presentations',
                icon: ChromeReaderMode,
                children: [
                    /*{
                        title: 'menu_item_presentations_faq_categories',
                        href: '/presentations/faq-categories',
                        icon: ChatBubble
                    },
                    {
                        title: 'menu_item_presentations_faq_questions',
                        href: '/presentations/faq-questions',
                        icon: QuestionAnswer
                    },
                    {
                        title:'menu_item_presentations_infos',
                        href:'/presentations/infos',
                        icon : Info,
                    },*/
                    {
                        title: 'Testimony',
                        href: '/presentations/testimony',
                        icon: SupervisorAccount,
                    },
                    /*{
                        title: 'menu_item_guide_voyage',
                        href: '/presentations/guidevoyage',
                        icon: FlightTakeoff,
                    },*/
                   
                ]
            },
            {
                title:'_mail',
                href:'/mail',
                icon :Send,
            },
            {
                title:'user_profile',
                href:'/userprofile',
                icon : AccountCircle,
            },


        ]
    }
];
