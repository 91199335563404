/* eslint-disable no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Button, Hidden, IconButton, Menu, MenuItem, Toolbar} from '@material-ui/core';
import {Menu as MenuIcon} from '@material-ui/icons';
import InputIcon from '@material-ui/icons/Input';
import {logout} from 'actions';
import {useTranslation} from 'react-i18next';
import useRouter from 'utils/useRouter';
import SecureLS from "secure-ls"
import {useDispatch} from "react-redux";
import Logo from "assets/Logo.svg";

var localStorage = new SecureLS({encodingType: 'aes'});

const useStyles = makeStyles(theme => ({
    root: {
        position: "fixed !important",
        boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)"
    },
    flexGrow: {
        flexGrow: 1
    },
    notificationsButton: {
        marginLeft: theme.spacing(1)
    },
}));

const TopBar = props => {
    const {onOpenNavBarMobile, className, ...rest} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const {history} = useRouter();
    const handleLogout = () => {
        dispatch(logout());
        setTimeout(()=>{
            history.push('/auth/login');
        }, 300) 
    };
    React.useEffect(() => {
        i18n.changeLanguage(localStorage.get("language") || "fr");
    }, []);
    const handleClose = (lng) => {
        if (typeof lng !== "undefined") {
            i18n.changeLanguage(lng.toLowerCase());
            localStorage.set("language", lng.toLowerCase());
        }
        setAnchorEl(null);
    };
    const classes = useStyles();

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="white"
        >
            <Toolbar>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        style={{color: "black"}}
                        onClick={onOpenNavBarMobile}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>

                <Hidden mdDown>
                    <img
                        style={{width: 256}}
                        />
                </Hidden>
                <img
                    style={{height: 50}}
                    alt="Logo"
                    src={Logo}
                />
                <div className={classes.flexGrow}/>
                <IconButton
                    style={{ color : '#1b70b9', fontSize : 18}}
                    onClick={handleClick}
                    className={classes.notificationsButton}
                >
                    <img
                        style={{height: 20, width: 20}}
                        alt="Logo"
                        src={`/images/${i18n.language}.svg`}
                    />
                </IconButton>
                <Button onClick={handleLogout} style={{color: "#1b70b9"}} startIcon={<InputIcon/>}>
                    {t('disconnect')}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => handleClose()}
                >
                    {
                        [{value: 'fr', title: 'Français'},
                            {value: 'de', title: 'Deutsch'},
                            {value: 'en', title: 'English'}].map(i => (
                                <MenuItem onClick={() => handleClose(i.value)} key={i.value}>
                                    <img
                                        style={{height: 20, width: 20, marginRight: 10}}
                                        alt="Logo"
                                        src={`/images/${i.value}.svg`}
                                    /> 
                                    {i.title}   
                                </MenuItem>
                            )
                        )
                    }
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
