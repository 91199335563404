import React from 'react';
import {Router} from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import {createBrowserHistory} from 'history';
import {Provider as StoreProvider} from 'react-redux';
import {ThemeProvider} from '@material-ui/styles';
import {renderRoutes} from 'react-router-config';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import theme from './theme';
import {configureStore} from './store';
import routes from './routes';
import config from "./config";
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import {SnackbarProvider} from "notistack";

const history = createBrowserHistory();
const store = configureStore();

console.log({config});

const App = () => {
    return (
        <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider maxSnack={5} anchorOrigin={{vertical: 'top', horizontal: 'right',}}>
                        <Router history={history}>
                            {renderRoutes(routes)}
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </StoreProvider>
    );
};

export default App;
