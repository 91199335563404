import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';

import {Search} from './components';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    search: {
        flexGrow: 1,
        maxWidth: 480,
        flexBasis: 480,
    },
    filterButton: {
        marginLeft: 'auto',

    },
    filterIcon: {
        marginRight: theme.spacing(1)
    }
}));

const SearchBar = props =>
{
    const {onFilter, loading, value, onChange, onSearch, withoutButton, className, placeholder, ...rest} = props;


    const classes = useStyles();

    const [openFilter, setOpenFilter] = useState(false);

    const handleFilterOpen = () => {
        setOpenFilter(true);
    };

    const handleFilterClose = () => {
        setOpenFilter(false);
    };

    return (
        <Grid
            {...rest}
            className={clsx(classes.root, className)}
            container
            spacing={3}
        >
            <Grid item>
                <Search
                    withoutButton={withoutButton}
                    className={classes.search}
                    onSearch={onSearch}
                    onChange={onChange}
                    value={value}
                    loading={loading}
                    placeholder={placeholder}
                />
            </Grid>
        </Grid>
    );

};

SearchBar.propTypes = {
    className: PropTypes.string,
    onSearch: PropTypes.func,
};

export default SearchBar;
