import * as actionTypes from '../actions';
import SecureLS from "secure-ls"

var localStorage = new SecureLS({encodingType: 'aes'});

let user;
let token;
try {
    user = localStorage.get("user") ? JSON.parse(localStorage.get("user")) : {};
    token = localStorage.get("token") || false;
    console.log(token)
} catch (err) {
    window.localStorage.clear();
    localStorage.resetAllKeys();
    window.location.reload()
}

const initialState = {
    loggedIn: !!token,
    token: token ? token : null,
    user: user,
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SESSION_LOGIN: {
            localStorage.set("token", action.payload.token);
            localStorage.set("user", JSON.stringify(action.payload.user));
            //console.log("value of reducer",action.payload)
            return {
                loggedIn: true,
                ...action.payload
            };
        }

        case actionTypes.SESSION_LOGOUT: {
            localStorage.remove("token");
            localStorage.remove("user");
            return {
                ...state,
                loggedIn: false,
                token: null,
                user: {},
            };
        }
        default: {
            return state;
        }
    }
};

export default AuthReducer;