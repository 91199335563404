import * as actionTypes from '../actions';

const initialState = {
    translators: null,
    messages: null,
    insurances: null,
    faq_category: null,
    faq_question: null,
    shooolLevels: null,
    semesters: null,
    materials: null,
    cursus: null,
    users : null,
    demandes : null,
    universities: null,
    banks: null,
    university: null,
    testimony: null,
    guidevoyage: null,
    info: null,
    preregistrations: null,
    products: null,
    level_of_school: [],
    request: null,
    emails:null,
};

const ListReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TRANSLATORS: {
            return {
                ...state,
                translators : action.payload
            };
        }
        case actionTypes.SET_USERS: {
            return {
                ...state,
                users : action.payload
            };
        }
        case actionTypes.SET_MESSAGES: {
            return {
                ...state,
                messages : action.payload
            };
        }
        case actionTypes.SET_INSURANCES: {
            return {
                ...state,
                insurances: action.payload
            };
        }
        case actionTypes.SET_SCHOOL_LEVEL:{
            return {
                ...state,
                shooolLevels: action.payload
            }
        }
        case actionTypes.SET_SEMESTER:{
            return {
                ...state,
                semesters: action.payload
            }
        }
        case actionTypes.SET_MATERIAL:{
            return {
                ...state,
                materials: action.payload
            }
        }
        case actionTypes.SET_CURSUS:{
            return {
                ...state,
                cursus: action.payload
            }
        }
        case actionTypes.SET_DEMANDES:{
            return {
                ...state,
                demandes: action.payload
            }
        }
        case actionTypes.SET_FAQ_CATEGORY: {
            return {
                ...state,
                faq_category: action.payload
            };
        }
        case actionTypes.SET_FAQ_QUESTIONS: {
            return {
                ...state,
                faq_question: action.payload
            };
        }
        case actionTypes.SET_UNIVERSITY: {
            return {
                ...state,
                universities: action.payload
            };
        }
        case actionTypes.SET_TESTIMONY: {
            return {
                ...state,
                testimony: action.payload
            };
        }
        case actionTypes.SET_GUIDEVOYAGE: {
            return {
                ...state,
                guidevoyage: action.payload
            };
        }
        case actionTypes.SET_BANKS: {
            return {
                ...state,
                banks: action.payload
            };
        }
        case actionTypes.SET_UNIVERSITY_DETAIL: {
            return {
                ...state,
                university: action.payload
            };
        }
        case actionTypes.SET_INFOS: {
            return {
                ...state,
                info: action.payload
            };
        }
        case actionTypes.SET_PREREGISTRATION_DATE: {
            return {
                ...state,
                preregistrations: action.payload
            };
        }
        case actionTypes.SET_PRODUCT: {
            return {
                ...state,
                products: action.payload
            };
        }
        case actionTypes.SET_LEVEL_OF_SCHOOL: {
            return {
                ...state,
                level_of_school: action.payload
            };
        }
        case actionTypes.SET_REQUEST_DETAIL: {
            return {
                ...state,
                request: action.payload
            };
        };
        case actionTypes.SET_EMAILS: {
            return {
                ...state,
                emails: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default ListReducer;