export const SET_TRANSLATORS = 'SET_TRANSLATORS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_INSURANCES = 'SET_INSURANCES';
export const SET_FAQ_CATEGORY = 'SET_FAQ_CATEGORY';
export const SET_FAQ_QUESTIONS = 'SET_FAQ_QUESTIONS';
export const SET_SCHOOL_LEVEL = 'SET_SCHOOL_LEVEL';
export const SET_SEMESTER = 'SET_SEMESTER';
export const SET_MATERIAL = 'SET_MATERIAL';
export const SET_CURSUS = 'SET_CURSUS';
export const SET_DEMANDES = 'SET_DEMANDES';
export const SET_USERS = 'SET_USERS';
export const SET_UNIVERSITY = 'SET_UNIVERSITY';
export const SET_TESTIMONY = 'SET_TESTIMONY';
export const SET_GUIDEVOYAGE = 'SET_GUIDEVOYAGE';
export const SET_UNIVERSITY_DETAIL = 'SET_UNIVERSITY_DETAIL';
export const SET_BANKS = 'SET_BANKS';
export const SET_INFOS = 'SET_INFOS';
export const SET_PREREGISTRATION_DATE = 'SET_PREREGISTRATION_DATE';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_LEVEL_OF_SCHOOL = 'SET_LEVEL_OF_SCHOOL';
export const SET_REQUEST_DETAIL = 'SET_REQUEST_DETAIL';
export const SET_EMAILS='.SET_EMAILS';

export const setTranslator = (payload) => dispatch =>

    dispatch({
        type: SET_TRANSLATORS,
        payload: payload
    });
export const setUsers = (payload) => dispatch =>

    dispatch({
        type: SET_USERS,
        payload: payload
    });

export const setMessages = (payload) => dispatch =>

    dispatch({
        type: SET_MESSAGES,
        payload: payload
    });

export const setInsurance = (payload) => dispatch =>
    dispatch({
        type: SET_INSURANCES,
        payload: payload
    });

export const setFaqCategory = (payload) => dispatch =>
    dispatch({
        type: SET_FAQ_CATEGORY,
        payload: payload
    });
export const setFaqQuestion = (payload) => dispatch =>
    dispatch({
        type: SET_FAQ_QUESTIONS,
        payload: payload
    });

export const setUniversity = (payload) => dispatch =>
   dispatch({
      type: SET_UNIVERSITY,
      payload: payload
   });
export const setTestimony = (payload) => dispatch =>
    dispatch({
        type: SET_TESTIMONY,
        payload: payload
    });
export const setGuideVoyage = (payload) => dispatch =>
    dispatch({
        type: SET_GUIDEVOYAGE,
        payload: payload
    });

export const setSchoolLevel = (payload) => dispatch =>
   dispatch({
      type: SET_SCHOOL_LEVEL,
      payload: payload
   });

export const setSemester = (payload) => dispatch =>
   dispatch({
      type: SET_SEMESTER,
      payload: payload
   });

export const setMaterial = (payload) => dispatch =>
   dispatch({
      type: SET_MATERIAL,
      payload: payload
   });
export const setCursus = (payload) => dispatch =>
    dispatch({
        type: SET_CURSUS,
        payload: payload
    });
    
export const setDemandes = (payload) => dispatch =>
    dispatch({
        type: SET_DEMANDES,
        payload: payload
    });

export const setBanks = (payload) => dispatch =>
   dispatch({
      type: SET_BANKS,
      payload: payload
   });

export const setDetail = (payload) => dispatch =>
   dispatch({
      type: SET_UNIVERSITY_DETAIL,
      payload: payload
   });

export const setInfo = (payload) => dispatch =>
   dispatch({
      type: SET_INFOS,
      payload: payload
   });

export const setPreregistration = (payload) => dispatch =>
   dispatch({
      type: SET_PREREGISTRATION_DATE,
      payload: payload
   });

export const setProduct = (payload) => dispatch =>
   dispatch({
      type: SET_PRODUCT,
      payload: payload
   });

export const setLevelOfSchool = (payload) => dispatch =>
    dispatch({
        type: SET_LEVEL_OF_SCHOOL,
        payload: payload
    });

export const setRequestDetail = (payload) => dispatch =>
    dispatch({
        type: SET_REQUEST_DETAIL,
        payload: payload
    });

export const setEmails = (payload)=> dispatch => 
    dispatch({
        type: SET_EMAILS,
        payload: payload
    });

