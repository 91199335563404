import React, {useRef, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import moment from 'moment';
    import {KeyboardDatePicker, DatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";

const useStyles = makeStyles(theme => ({}));

export default function DateField(props) {
    const classes = useStyles();
    const fileInputRef = useRef(null);
    const {onChange, ...rest} = props;
    const [date, setDate] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);
    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <div>
            <TextField
                {...rest}
                type={'text'}
                onFocus={() => {
                    if (!isFocus) {
                        setOpen(true);
                        setIsFocus(true);
                    }else {
                        setIsFocus(false);
                    }
                }}
                value={value}
            />
            <div style={{display : 'none'}}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'fr'}>
                    <DatePicker
                        open={open}
                        onClose={() => setOpen(false)}
                        ref={fileInputRef}
                        fullWidth
                        value={date}
                        onChange={date => {
                            const newd = date._d;
                            const m = new moment(newd);
                            onChange(m.format('YYYY-MM-DD'));
                            setDate(date._d)
                        }}
                        inputVariant="outlined"
                        views={["date", "month", "year"]}
                        format="dd/MM/YYYY"
                        InputAdornmentProps={{position: "start"}}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
}