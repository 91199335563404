import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Avatar, Button, Card, CardContent, colors, Link, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import getInitials from 'utils/getInitials';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import "moment/locale/fr";
import "moment/locale/de";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2)
    },
    content: {
        padding: theme.spacing(2),
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexWrap: 'wrap'
        },
        '&:last-child': {
            paddingBottom: theme.spacing(2)
        }
    },
    header: {
        maxWidth: '100%',
        width: 240,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            flexBasis: '100%'
        }
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    stats: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%'
        }
    },
    actions: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%'
        }
    }
}));

const DemandesCard = props => {
    const {loading, message, className, ...rest} = props;

    const classes = useStyles();
    const {t, i18n} = useTranslation();


    const statusColors = {
        'In progress': colors.orange[600],
        Canceled: colors.grey[600],
        Completed: colors.green[600]
    };

    moment.locale(i18n.language);

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
                <div className={classes.header}>
                    <Avatar
                        alt="Author"
                        className={classes.avatar}
                        src={message.info.university.logo_url}
                    >
                        {getInitials(message.info.university.name)}
                    </Avatar>

                    <div>
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            noWrap
                            to="#"
                            variant="h5"
                        >
                            {message.user.lastname.firstname}
                            {message.name}
                        </Link>

                        {message.user.firstname} {message.user.lastname}
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/projects/1/overview"
                            variant="h5"
                        >

                        </Link>

                        <Typography variant="body2">
                            <div>
                                <Box component="div" display="inline" p={0} m={0} bgcolor="background.paper">
                                    {t('pour')}
                                </Box>

                                <Box component="div" display="inline" p={0.5} m={0} bgcolor="background.paper">
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/studies/university"
                                    variant="h6"
                                >
                                    {message.info.university.name}
                                </Link>
                                </Box>
                            </div>
                            <div></div>
                        </Typography>

                    </div>
                </div>
                <div className={classes.stats}>
                    <Typography variant="h6">
                        {t(message.request_type)}
                    </Typography>
                    <Typography variant="body2">{t('request_type')}</Typography>
                </div>
                <div className={classes.stats}>
                    <Typography variant="h6">
                        {message.info.curriculum[`title_${i18n.language}`]}
                    </Typography>
                    <Typography variant="body2">{t('curriculum_title')}</Typography>
                </div>
                <div className={classes.stats}>
                    <Typography variant="h6">
                        <Box component="div" display="inline" p={0} m={0} bgcolor="background.paper">{message.info.subject[`title_${i18n.language}`]}</Box>/
                        <Box component="div" display="inline" p={0} m={0} bgcolor="background.paper">{message.info.semester[`title_${i18n.language}`]}</Box>
                    </Typography>
                    <Typography variant="body2">{t('matiere et semestre')}</Typography>
                </div>

                <div className={classes.stats}>
                    <Typography variant="h6">
                        {moment(message.date_added).format('MMMM Do YYYY, h:mm:ss')}
                    </Typography>
                    <Typography variant="body2">{t('date_added')}</Typography>
                </div>
                <div className={classes.actions}>
                    <Button
                        component={RouterLink}
                        to={{
                            pathname: `/requests/${message.id}`,
                            demande: message,
                            loading: loading
                        }}
                        color="primary"
                        size="small"
                        variant="outlined"
                    >
                        {t('see')}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

DemandesCard.propTypes = {
    className: PropTypes.string,
    message: PropTypes.object.isRequired
};

export default DemandesCard;

