import React from "react";
import {Dialog, Button, DialogActions, DialogTitle, DialogContentText, DialogContent} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from "react-i18next";

const ConfirmationDialog = ({open, onConfirmDialogClose, text, loading, title = "Confirmer", onYesClick}) => {
   const {t} = useTranslation();

   return (
      <Dialog
         maxWidth="xs"
         fullWidth={true}
         open={open}
         onClose={onConfirmDialogClose}
      >
         <DialogTitle>{t('confirmation')}</DialogTitle>
         <DialogContent>
            <DialogContentText>{text}</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Box display={'inline'} position={'relative'}>
               {
                  loading &&
                  <Box style={{
                     position: 'absolute',
                     left: '50%',
                     top: '50%',
                     transform: 'translate(-50%, -50%)'
                  }}>
                     <CircularProgress size={24}/>
                  </Box>
               }
               <Button disabled={loading} onClick={onYesClick} style={{marginRight: 10}} variant="contained"
                       color="primary">
                  {t('yes')}
               </Button>
            </Box>

            <Button
               onClick={onConfirmDialogClose}
               variant="contained"
               color="secondary"
            >
               {t('no')}
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default ConfirmationDialog;
