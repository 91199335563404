export default {
    translation: {

        "testimony_find": "Temoignages Trouvés",
        "Contenu_Guide_Voyage": "Contenu Guide Voyage",
        "Updated": "Mis a jour",
        "Emploi": "Emploi",
        "Formation": "Formation",
        "Etude": "Etude",
        "Ajouter_une_Demande": "Ajouter une Demande",

        "service": "Service",
        "request_number": "Nombre de demande supplémentaire",
        "add_request_label": "Ajouter une Demande supplementaire",
        "menu_item_dashboard": "Tableau de bord",
        "menu_item_studies_semester": "Semestre",
        "menu_item_banks": "Banques",
        "menu_item_assurances": "Assurances",
        "menu_item_translators": "Traducteurs",
        "menu_item_messages": "Messages",
        "menu_item_presentations": "Contenus",
        "menu_item_presentations_faq_categories": "Catégories FAQ",
        "menu_item_presentations_faq_questions": "Questions FAQ",
        "menu_item_requests": "Demandes",
        "menu_item_orders": "Achats",
        "menu_item_services": "Services",
        "welcome": "Bienvenu",
        "detail": "Détail",
        "nom": "Nom",
        "email": "Email",
        "translator": "Traducteur",
        "link": "Lien",
        "delete-message": "Supprimer le message ",
        "delete-translator": "Supprimer le traducteur ",
        "object": "Objet",
        "author": "Auteur",
        "error-file-type": "Vous devez importer une image au format JPG ou PNG",
        "delete": "Supprimer",
        "see": "Voir",
        "actions": "Actions",
        "message": "Message",
        "messages": "Messages",
        "send": "Envoyer",
        "add-translator": "Ajouter un traducteur",
        "update": "Mettre a jour",
        "addcomplete_name": "Nom complet",
        "connect-to-admin": "Se Connecter a l'espace d'administration",
        "connect": "Se Connecter",
        "password": "Mot de passe",
        "next": "Suivant",
        "translator_language": "Langue du traducteur",
        "preview": "Précedent",
        "response": "Répondre",
        "responses": "Réponses",
        "all-message": "Tout les messages",
        "list-message": "Liste des messages",
        "reload": "Recharger",
        "keyword": "Rechercher...",
        "page": "Page",
        "translator-field-link-error": "^Svp veuillez fournir un lien",
        "translator-field-language-error": "^Svp veuillez fournir votre la langue du traducteur",
        "translator-field-name-error": "^Svp veuillez fournir votre le nom complet du traducteur",
        "edit": "Editer",
        "translators": "Traducteurs",
        "message-find": "Messages trouvés",
        "translator-find": "Traducteurs trouvés",
        "disconnect": "Déconnexion",
        "any-response": "Auccune réponse pour le moment.",
        "forward-for": "Réponses pour",
        "activate-translator": "Activé le traducteur ",
        "activate": "Activer",
        "disable-translator": "Désactiver le traducteur ",
        "disable": "Désactiver",
        "check-connexion": "Vérifier votre connexion internet",
        "confirm-delete": "Confirmer la suppréssion",
        "insurances": "Assurances",
        "insurance": "Assurance",
        "insurance-description": "Description",
        "disable-insurance": "Désactiver l'assurance",
        "delete-insurance": "Supprimer  l'assurance",
        "price": "Prix",
        "add-insurance": "Ajouter une assurance",
        "all-insurances": "Toutes les assurances",
        "list-insurance": "Liste des assurances",
        "insurance-field-name-error": "^Svp veuillez fournir votre le nom complet de l'assurance",
        "insurance-field-description-fr-error": "^Svp veuillez renseigner la description en français",
        "insurance-field-description-en-error": "^Svp veuillez renseigner la description en en anglais",
        "insurance-field-description-de-error": "^Svp veuillez renseigner la description en en allemand",
        "insurance-price-error": "^Svp veuillez renseigner le prix pour cette assurance",
        "insurance-field-link-error": "^Svp veuillez fournir un lien",
        "add-insurance-name": "Nom complet",
        "add-description-fr": "Description en français",
        "add-description-en": "Description en anglais",
        "add-description-de": "Description en allemand",
        "add-insurance-price": "Prix",
        "insurance-find": "Assurances trouvées",
        "menu_item_studies_niveaux_de_langues": "Niveaux de langues",
        "add-schoolLevel-title-fr": "Titre du niveau en français",
        "add-schoolLevel-title-en": "Titre du niveau en anglais",
        "add-schoolLevel-title-de": "Titre du niveau en allemand",
        "schoolLevel-field-title-fr-error": "^Svp veuillez renseigner le titre en français",
        "schoolLevel-field-title-en-error": "^Svp veuillez renseigner le titre en anglais",
        "schoolLevel-field-title-de-error": "^Svp veuillez renseigner le titre en allemend",
        "title": "Titre",
        "school-level-title": "Niveaux de langue",
        "all-school-level": "Tous les niveaux",
        "school-level-find": "Niveaux trouvés",
        "add-school-level": "Ajouter un niveau de langue",
        "delete-school-level": "Supprimer le niveau ",
        "disable-school-level": "Désactiver le niveau",
        "enable-school-level": "Activer le niveau",


        "menu_item_presentations_infos": "Informations",
        "menu_item_products": "Produits",


        "cursus-title": "cursus",
        "edition": "Editer",
        "close": "Fermer",
        "add": "Ajouter",
        "cursus_title": "cursus",
        "add-cursus": "Ajouter un cursus",

        "upload": "Charger",
        "all-cursus": "Tous Les Cursus",
        "add-cursus-title-fr": "Titre du cursus en francais",
        "add-cursus-title-en": "Titre du cursus en anglais",
        "add-cursus-title-de": "Titre du cursus en allemend",
        "disable-cursus": "desactiver le cursus",
        "enable-cursus": "desactiver le cursus",


        "add-semester-title-fr": "Titre du semestre en français",
        "add-semester-title-en": "Titre du semestre en anglais",
        "add-semester-title-de": "Titre du semestre en allemand",
        "semester-field-title-fr-error": "^Svp veuillez renseigner le titre en français",
        "semester-field-title-en-error": "^Svp veuillez renseigner le titre en anglais",
        "semester-field-title-de-error": "^Svp veuillez renseigner le titre en allemend",
        "semester-title": "Semestres",
        "all-semester": "Tous les semestres",
        "semester-find": "Semestres trouvés",
        "add-semester": "Ajouter un nouveau semestre",
        "delete-semester": "Supprimer le semestre ",
        "disable-semester": "Désactiver le semestre",
        "enable-semester": "Activer le semestre",

        "add-material-title-fr": "Titre de la matière en français",
        "add-material-title-en": "Titre de la matière en anglais",
        "add-material-title-de": "Titre de la matière en allemand",
        "material-field-title-fr-error": "^Svp veuillez renseigner le titre en français",
        "material-field-title-en-error": "^Svp veuillez renseigner le titre en anglais",
        "material-field-title-de-error": "^Svp veuillez renseigner le titre en allemend",
        "material-title": "Matières",
        "all-material": "Toutes les matières",
        "material-find": "Matières trouvées",
        "add-material": "Ajouter une nouvelle matière",
        "delete-material": "Supprimer la matière",
        "disable-material": "Désactiver la matière",
        "enable-material": "Activer la matière",


        "category": "Categorie",
        "add_category": 'Ajouter une catégorie',
        "list_category_faq": 'Liste de categories de faq',
        "faq_category": 'Categories de faq',
        "all_faq_category": 'Toutes les categories de faq',
        "faq_description": 'Intitulé',
        "delete_category": 'Supprimer la categorie ',
        "faq_category_find": 'Categories de faq trouvés',


        "add_question": 'Ajouter une question',
        "list_question_faq": 'Liste de question de faq',
        "faq_question": 'Questionss de faq',
        "all_faq_question": 'Toutes les questions de faq',

        "faq_question_find": 'Questions de faq trouvés',
        "delete_question": "Supprimer la question",
        "question_response": "Réponse",
        "faq_question_title": 'Question',
        "question_fr": "Question en français",
        "response_fr": "Réponse en français",
        "question_de": "Question en allemand",
        "response_de": "Réponse en allemand",
        "question_en": "Question en anglais",
        "response_en": "Réponse en anglais",
        "loading_category": "Chargement des categorie",
        "select_category": "Veuillez selectioner une category",
        "empty_category": "Aucune categorie disponible",


        "menu_item_studies_university": "Universités",
        "menu_item_studies_materials": "Matière",
        "menu_item_studies_curriculum": "cursus",
        'menu_item_users': 'Utilisateurs',
        "menu_item_studies": "Etudes",

        'user': 'Utilisateur',


        'enable_user': 'Activer le compte de l\'utilisateur',
        'disable_user': 'Désactiver le compte de l\'utilisateur',
        'delete_user': 'Supprimer l\'utilisateur',
        'list_users': 'Liste des utilisateurs',
        'empty-field-string': '^Veuillez completer ce champ',
        'empty-field-email': '^Veuillez une addresse email correcte',
        'add_user': 'Ajouter un utilisateur',
        'firstname': 'Pénom',
        'lastname': 'Nom',
        'role': 'Rôle',
        'phonenumber': 'Numéro de téléphone',
        'birthday': 'Date de naissance',
        'admin': 'Administrateur',
        'contact_role': 'Contact et rôle',
        'sub_admin': 'Sous-Administrateur',
        "all-translator": "Tout les traducteur",
        "list-translator": "Liste des traducteurs",
        'User': 'Utilisateur',

        "page_bank_infos_all_bank": "Toutes les banques",
        "page_bank_infos__banks_find": "Banques trouvées",
        "list-banks": "Liste des banques",
        "add-bank": "Ajouter une banques",
        "empty-field-price": "^Veuillez fournir un prix",
        "empty_field_phone_number": "^Veuillez ajouter un numéro de téléphone",
        "phone_number": "Numéro de téléphone",
        "name_bank": "Nom de la banque",
        "name": "Nom",
        "delete-bank": "Supprimer la banque ",
        "activate-bank": "Activer la banque ",
        "disable-bank": "Deactiver la banque ",
        "confirmation": "Confirmation",
        "description": "Description",
        "banks": "Banques",
        "personnel_info": "Informations personnelles",
        "description_fr": "Description en Français",
        "description_en": "Description en Anglais",
        "description_de": "Description en Allemand",


        "add_university_name": "Nom de l'université",
        "document_traduction": "Documents traduisible ",
        "add_university_cost": "Coût de la demande",
        "university_field_title_fr_error": "^Svp veuillez renseigner le nom de l'université",
        "university_field_document_traduction_error": "^Svp veuillez renseigner le document de traduction",
        "request_cost_error": "^Svp veuillez renseigner le coût de la demande",
        "school_level_error": "^Svp veuillez renseigner au moins un niveau de langue",
        "university_title": "Universités",
        "all_universities": "Toutes les Universités",
        "university_find": "Universités trouvées",
        "add_university": "Ajouter une nouvelle université",
        "delete_university": "Supprimer l'université",
        "disable_university": "Désactiver l'université ",
        "enable_university": "Activer l'université",
        "add_university_cursus_info": "Ajouter des matières à ce cursus",
        "pre_registration_date": "Date de pré-inscription",
        "pre_registration_date_start": "Début",
        "pre_registration_date_end": "Fin",
        "date_validator": "Veuillez remplir tous les champs",
        "dates_error": "La date de fin est inférieure ou égale à la date de début",
        "delete_preregistration_date": "Supprimer cette de date de pré-inscription ?",
        "explanation_document": "Document d'explication",
        "description_explanation_document": "Document expliquant les démarches à suivre pour s'inscrire dans cet établissement.",
        "upload_file": "importer le fichier",
        "change_file": "Changer le fichier",

        "products": "Produits",
        "choose_service": "Choisir ce service",
        "add_advantage_title_fr": "Titre de l'avantage en français",
        "add_advantage_title_en": "Titre de l'avantage en anglais",
        "add_advantage_title_de": "Titre de l'avantage en allemand",
        "advantage_field_title_fr_error": "^Svp veuillez renseigner le titre en français",
        "advantage_field_title_en_error": "^Svp veuillez renseigner le titre en anglais",
        "advantage_field_title_de_error": "^Svp veuillez renseigner le titre en allemend",
        "delete_advantage": "Supprimer cet avantage ?",

        "previous": "precedant",

        "assurance_maladie": "Assurance maladie",
        "responsabilite_civile": "Assurance responsabilité civile",
        "subscription_guide_file": "Guide de souscription",
        "consult": "Consulter",
        "page_university_preregistration_link": "Lien de pré-inscription",

        "all_Request": "toutes les demandes",
        "add_demandes": "Ajouter une demande",
        "menu_item_request": "Demandes",
        "request_find": "Demandes trouvées",
        "request_type": "Type de la requête",
        "curriculum_title": "Cursus",
        "date_added": "Date de la demande",

        "menu_item_studies_requetes": "Demandes",
        "add-requete": "Ajouter une requete",
        "add-requete-title-de": "Ajouter la requete",
        "all-requete": "Tous Les Cursus",
        "add-requete-title-fr": "Titre de la  requete en francais",
        "add-requete-title-en": "Titre de la requete en anglais",

        'menu_item_cursus': 'Cursus',

        "info-field-address-error": "Veuillez ajouter une addrese valide",
        "info-field-phone-error": "Veuillez ajouter une numéro de télephone valide",
        "info-field-facebook-error": "veuillez entrer un compte facebook valide",
        "info-field-whatsapp-error": "veuillez entrer un compte whatsapp valide",
        "user_profile": "Mon Profil",
        "change_your_avatar": "changer mon avatar",
        "error_profile": "Nous n'avons pas pu mettre a jour votre avatar!",
        "Connexion": "Connexion",
        "Actual_Password": "Mot de passe Actuel",
        "New_Password": "Nouveau Mot de passe",
        "Profile": "Profil",
        "country": "Pays",
        "residence_place": "Lieu de résidence",
        "nationality": "Nationalité",
        "whatsapp": "WhatsApp",
        "how_understand_about_us": "Comment nous comprendre",
        "kind": "Genre",
        "woman": "Femme",
        "man": "Homme",
        "save": "Sauvegarder",
        "purchase": "Achat",
        "record": "Enregistrement",
        "found": "trouvé",
        "of": "sur",
        "operator": "Opérateur",
        "services_order": "Services Commander",
        "payment_method": "Moyen de Paiement",
        "page_university_info_id": "Identifiant",
        "page_university_chosed_number": "Nombre de selection",
        "menu_item_stats": "Statistiques",
        "page_statistics_services_title": "Statistiques des services",
        "page_statistics_services_bank_title": "Nombre de selection / Banque",
        "page_statistics_services_assurance_title": "Nombre de selection / Assurance",
        "page_statistics_services_translator_title": "Nombre de selection / Traducteur",
        "page_statistics_show_all_btn": "Tout afficher",
        "page_statistics_studies_title": "Statistiques choix pour demandes etudes",
        "page_statistics_studies_university_title": "Nombre de selection / Université",
        "page_statistics_studies_language_level_title": "Nombre de selection / Niveau de langue",
        "page_statistics_studies_subjects_title": "Nombre de selection / Matière",
        "page_statistics_studies_curriculum_title": "Nombre de selection / Cursus",
        "page_statistics_studies_semester_title": "Nombre de selection / Semestre",
        "STUDIE": "ETUDE",
        "JOB": "TRAVAIL",
        "TRAINING": "FORMATION",
        "AUPAIR": "AU PAIR",
        "page_request_detail_label_university_name": "Université",
        "page_request_detail_label_status": "Statut",
        "page_request_detail_label_answer": "Répondre",
        "page_request_detail_label_cancel": "Annuler",

        "page_requests_status_online": "Statut de la demande en ligne",
        "page_requests_status_send_germany": "Courrier envoyé en Allemagne",
        "page_requests_status_arrived_germany": "Courrier arrivé en Allemagne",
        "page_requests_status_send_university": "Courrier envoyé à l'université",
        "page_requests_status_preinscription_status": "Préinscription disponible",
        "page_request_detail_title_field_error": "^Veuillez renseigner le titre svp",
        "page_request_detail_label_title_mail": "Titre du message",
        "page_request_detail_delete_confirm": "Voulez-vous supprimer cette demande ?",
        "page_request_detail_label_card_send_mail": "Envoyer un e-mail",
        "page_request_detail_status": "Statut",

        "page_request_filter_option_clear": "Effacer",
        "page_request_filter_option_apply": "Filtrer",

        "page_request_show_filter": "Filtres",
        "page_request_show_group_student_label": "Etudiant",
        "page_request_show_student_label_email": "E-mail",
        "page_request_show_student_label_name": "Nom",
        "page_request_show_student_label_sex": "Sexe",

        "page_request_show_group_request_label": "Demande",
        "page_request_show_request_label_type": "Type",
        "page_request_show_request_label_date_range": "Plage de date",
        "page_request_show_request_label_status": "Statut",


        'request_status_accepted': 'Accepté',
        'request_status_reject': 'Refusé',
        'request_status_in_wait': 'En attente',
        'request_status_in_end': 'Terminé',
        'request_status_in_pendding': 'Non terminé',
        'request_status_yes': 'Oui',
        'request_status_no': 'Non',
        'request_button_guide': 'Commencer',

        "all_travelguide": "Tous les guides de voyage",
        "Learn_more": "En savoir plus",
        "title_fr": "titre en français",
        "title_de": "titre en allemand",
        "content_fr": "contenu en français",
        "content_de": "contenu en allemand",
        "delete-guidevoyage": "supprimer le guide de voyage",
        "guidevoyage_find": "guide de voyage trouvé",
        "add-guidevoyage": "Ajouter un guide de voyage",
        "guide_voyage_title": "Guide de voyage",
        "menu_item_guide_voyage": "Guide de voyage",
        "enable-testimony": "permettre le témoignage",
        "desable-testament": "témoignage desable",
        "supprimer-témoignage": "supprimer le témoignage",
        "Image et nom": "ImageAdd and name",
        "add-testimony": "Ajouter un témoignage",
        "tout témoignage": "All Testimony",
        " nom_complet ": " Nom complet ",
        "commentaire": "Commentaire",
        "date": "date de commentaire",
        "profession": "Profession",

        "all_testimony": "Tout témoignage",
        "testimony_title": "Témoignage",
        "Témoignage": "Témoignage",
        "profil": "Profil",
        "Image": "Image",
        "yes": "Oui",
        "no": "Non",
        "public_id": "public_id",
        "add_request_name": "Ajouter le nom de la demande",
        "curriculum": "Cursus",
        "Appliquer des filtres": "Appliquer un filtre",
        "filter": "filtre",
        "requestStatus": "Statut de la demande",
        "etiquette de requete": "Etiquette de requete",
        "montant de la demande": "Nombre de demande",
        "ended": "terminé",
        "onGoing": "onGoing",
        "completed": "terminé",
        "customerName": "nom du client",
        "matiere et semestre": "Matière et Semestre",
        "pour": "pour",
        "translator-field-phone_number-error": "^ Veuillez indiquer le numéro de téléphone du traducteur",
        "translator-field-email-error": "^ Veuillez indiquer l'adresse e-mail du traducteur",
        "check-connection": "Vérifiez votre connexion internet",
        "enable-insurance": "Activer l'assurance",
        "error-field-name-error": "^ Veuillez indiquer le nom complet de l'assurance",
        "add-insurance-link": "Lien",
        "adress": "Adresse",

        "title_en" : " Titre en anglais",
        "content_en" : "contenu en français",
        "desable-testimony" : "désactiver le témoignage",
        "delete-testimony" : "Supprimer ce témoignage",
        "all-testimony" : "tous les témoignages",
        "complete_name" : "nom complet",
        "commentary" : "commentaire",
        "Testimony" : "Témoignage",
        "profile" : "profil",
        "Détail" : "Détail",
        "Name" : "Nom",
        "Edition" : "Edition",
        "Update" : "Mise à jour",
        "Password" : "Mot de passe",
        "load_category" : "Charger des catégories",
        "date_error" : "La date de fin est inférieure ou égale à la date de début",
        "liability_civile" : "assurance responsabilité civile",
        "personal_info" : "Informations personnelles",
        "Apply filters" : "Appliquer un filtre",
        "request amount" : "Nombre de demandes",
        "Detail" : "Détail",

        "address" : "adresse",
        "change_your_avatar " : "changez mon avatar",
        "nom_complet" : "Nom_complet",
        "nom_complet " : "Nom_complet",


        "page_product_form_edit_real_price_label": "Coût réel",
        "page_index_request_by_sex": "Requêtes par sexe",
        "page_index_request_by_month": "Nombre de requêtes par mois",
        "page_index_request_by_month_this_year": "Cette année",
        "page_index_request_by_month_last_year": "Année précedente",
        "page_index_request_by_month_JAN": "JAN",
        "page_index_request_by_month_FEB": "FEV", 
        "page_index_request_by_month_MAR": "MAR",
        "page_index_request_by_month_APR": "AVR",
        "page_index_request_by_month_MAY": "MAI",
        "page_index_request_by_month_JUN": "JUI",
        "page_index_request_by_month_JUL": "JUL",
        "page_index_request_by_month_AUG": "AUO",
        "page_index_request_by_month_SEP": "SEP",
        "page_index_request_by_month_OCT": "OCT", 
        "page_index_request_by_month_NOV": "NOV", 
        "page_index_request_by_month_DEC": "DEC", 
        "page_index_title": "Aperçu des statistiques", 
        "page_index_orders_amount": "Montant commande",
        "page_index_orders_number": "Nombre commande",
        "page_index_registrations": "Nombre inscription",
        "page_index_requests": "Nombre demande",

        "page_product_label_bonus": "Bonus ",

        "page_studies_level_table_header_priority": "Priorité",
        "page_studies_level_form_error_empty_field_priority": "^Veuillez renseigner la priorité",


        "_mail":'Mail',
        "_send_mail":"Envoyer un mail",
        "mail_find":'mail trouvés',
        "delete_mail":'Supprimer le mail ',
        "_delete_mail":'voulez-vous supprimer le mail de ',
        "all_mail": 'Tous les mails',
        "_avatar":"avatar",
        "_name_and_surname":"nom et prnom",
        "_object":"object",
        "_content":"contenu",
        "_mail_option":"option",
        "_mail_action":"action",
        "_message":"message",
        "TO_EVERYONE":"envoyer à tout le monde",
        "SERVICE_PAID":"envoyer à ceux qui ont déja payer un service",
        "NOT_SERVICE_PAID":"envoyer à ceux qui n'ont jamais paye un service",
        "SEND_REQUEST":" envoyer à ceux qui ont deja soumis une requête",
        "NOT_SEND_REQUEST":" envoyer à ceux qui n'ont jamais soumis une requête",

        'global_action_enable': 'Activer',
        'global_action_disable': 'Desactiver',
        'testimony_dialog_activate': 'Activer ce temoignage ?',
        'testimony_dialog_disable': 'Desactiver ce temoignage ?',
        'testimony_info_char_max_number': 'Pour un meilleur rendu, le commentaire ne doit pas depasser 200 caractères',
        "page_studies_level_form_error_leer_field_priority": "^ Veuillez indiquer la priorité",

        'user-find':'utilisateurs trouvés',
        'date_send':"date de reception"
    
    }
};