export default {
    translation: {


        "Contenu_Guide_Voyage": "Travel Guide Content",
        "Updated": "Updated",
        "Emploi": "Employment",
        "Formation": "Training",
        "Etude": "Study",

        "service": "Service",
        "request_number": "Number of additional requests",
        "add_request_label": "Add an additional request",
        "all_travelguide": "All travel guides",
        "Learn_more": "Learn more",
        "title_fr": "title in french",
        "title_en": "title in english",
        "title_de": "title in german",
        "content_fr": "content in french",
        "content_en": "content in english",
        "content_de": "content in german",
        "delete-guidevoyage": "delete travel guide",
        "guidevoyage_find": "travel guide finded",
        "add-guidevoyage": "Add a Travel Guide",
        "guide_voyage_title": "Travel Guide",
        "menu_item_guide_voyage": "Travel Guide",
        "enable-testimony": "enable testimony",
        "desable-testimony": "desable testimony",
        "delete-testimony": "delete testimony",
        "Image et nom": "ImageAdd and name",
        "add-testimony": "Add a Testimony",
        "all-testimony": "All Testimony",
        "complete_name": " Full name",
        "commentary": "Commentary",
        "date": "Comment date",
        "profession": "Profession",
        "testimony_find": "Testimony find",
        "all_testimony": "All testimony",
        "testimony_title": "Testimony",
        "Testimony": "Testimony",
        "profile": "Profile",
        "Image": "Picture",
        "all_Request": "all requests",
        "add_demandes": "add request",
        "menu_item_request": "requests",
        "yes": "Yes",
        "no": "No",
        "request_type": "Request type",
        "curriculum_title": "Curriculum",
        "public_id": "public_id",
        "date_added": "date of request",
        "add_request_name": "add request name",
        "curriculum": "curriculum",
        "request_find": "requests find",
        "Apply filters": "Apply filter",
        "filter": "filter",
        "requestStatus": " request status",
        "etiquette de requete": " request tag",
        "request amount": "number of request",
        "ended": "ended",
        "onGoing": "onGoing",
        "completed": "completed",
        "customerName": "Customer name",
        "matiere et semestre": "subject and semester",
        "pour": "for",

        "menu_item_dashboard": "Dashboard",
        "menu_item_studies": "Studies",
        "menu_item_studies_university": "Universities",
        "menu_item_studies_materials": "Materials",
        "menu_item_studies_curriculum": "Curriculum",
        "menu_item_studies_semester": "Semester",
        "menu_item_banks": "Banks",
        "page_bank_infos_all_bank": "All banks",
        "page_bank_infos__banks_find": "Banks found",
        "activate-bank": "Activate bank",
        "disable-bank": "Deactivate the bank",
        "add-bank": "Add a bank",
        "menu_item_assurances": "Insurances",
        "menu_item_translators": "Translators",
        "menu_item_messages": "Messages",
        "menu_item_presentations": "Contents",
        "menu_item_presentations_faq_categories": "Categories FAQ",
        "menu_item_presentations_faq_questions": "FAQ questions",
        "menu_item_requests": "Requests",
        "menu_item_orders": "Purchases",
        "menu_item_services": "Services",
        "menu_item_presentations_infos": "News",
        "menu_item_products": "Products",
        "welcome": 'Welcome',
        "detail": "Detail",
        "nom": "name",
        "email": "Email",
        "translator": "Translator",
        "link": "Link",
        "delete-message": "Delete message",
        "delete-translator": "Delete translator",
        "object": "Object",
        "author": "Author",
        "error-file-type": "You must import an image in JPG or PNG format",
        "delete": "Delete",
        "see": "See",
        "actions": "Actions",
        "message": "Message",
        "messages": "Messages",
        "send": "Send",
        "add-translator": "Add a translator",
        "update": "Update",
        "addcomplete_name": "Full name",
        "connect-to-admin": "Connect to the administration area",
        "connect": "Connect",
        "password": "Password",
        "phone_number": "Phone number",
        "translator_language": "Translator language",
        "preview": "Previous",
        "response": "Reply",
        "responses": "Responses",
        "all-message": "All messages",
        "list-message": "List of messages",
        "keyword": "Search...",
        "list-translator": "List of translators",
        "translator-field-link-error": "^ Please provide a link",
        "translator-field-language-error": "^ Please provide your translator language",
        "translator-field-name-error": "^ Please provide your full translator name",
        "translator-field-phone_number-error": "^ Please provide your translator phone number",
        "translator-field-email-error": "^ Please enter the translator's e-mail address",
        "empty_field_phone_number": "^ Bitte fügen Sie eine Telefonnummer hinzu",
        "edit": "Edit",
        "translators": "Translators",
        "message-find": "Messages found",
        "translator-find": "Translators found",
        "disconnect": "Disconnection",
        "any-response": "No response yet.",
        "forward-for": "Responses for",
        "activate-translator": "Activate the translator",
        "activate": "Activate",
        "disable-translator": "Disable the translator",
        "disable": "Disable",
        "check-connection": "Check your internet connection",
        "confirm-delete": "Confirm deletion",
        "insurances": "Insurances",
        "insurance": "Insurance",
        "insurance-description": "Description",
        "add-insurance": "Add insurance",
        "delete-insurance": "Delete insurance",
        "disable-insurance": "Disable insurance",
        "enable-insurance": "Activate insurance",
        "whatsapp": "WhatsApp",
        "page_university_preregistration_link": "Pre-registration link",
        "all-insurances": "All Insurance",
        "list-insurance": "List of insurances",
        "error-field-name-error": "^ Please provide your full insurance name",
        "insurance-field-description-fr-error": "^ Please fill in the description in French",
        "insurance-field-description-en-error": "^ Please enter the description in English",
        "insurance-field-description-de-error": "^ Please enter the description in German",
        "insurance-field-link-error": "^ Please provide a link",
        "add-insurance-name": "Full name",
        "add-description-fr": "Description in French",
        "add-description-en": "Description en anglais",
        "add-description-de": "Description in German",
        "add-insurance-link": "Link",
        "add-insurance-price": "Price",
        "insurance-price-error": "^ Please enter the price for this insurance",
        "insurance-find": "Insurance found",
        "menu_item_studies_niveaux_de_langues": "Languages levels",
        "add-schoolLevel-title-fr": "Titre du niveau en français",
        "add-schoolLevel-title-en": "Title of the level in English",
        "add-schoolLevel-title-de": "Title of level in German",
        "schoolLevel-field-title-fr-error": "^ Please fill in the title in French",
        "schoolLevel-field-title-en-error": "^ Please enter the title in English",
        "schoolLevel-field-title-de-error": "^ Please enter the title in German",
        "title": "Title",
        "school-level-title": "Language levels",
        "all-school-level": "All levels",
        "school-level-find": "Levels found",
        "add-school-level": "Add a language level",
        "delete-school-level": "Delete level",
        "disable-school-level": "Disable level",
        "enable-school-level": "Activate the level",
        "add-semester-title-fr": "Title of the semester in French",
        "add-semester-title-en": "Title of the semester in English",
        "add-semester-title-de": "Title of the semester in German",
        "semester-field-title-fr-error": "^ Please fill in the title in French",
        "semester-field-title-en-error": "^ Please enter the title in English",
        "semester-field-title-de-error": "^ Please enter the title in German",
        "semester-title": "Semesters",
        "all-semester": "All semesters",
        "semester-find": "Semesters found",
        "add-semester": "Add a new semester",
        "delete-semester": "Delete semester",
        "disable-semester": "Deactivate semester",
        "enable-semester": "Activate the semester",

        'menu_item_users': 'Users',

        'menu_item_cursus': 'Curriculum',
        "cursus-title": "curriculum",
        "add-cursus": "Ajouter un cursus",

        "reload": "Reload",
        "edition": "Edit",
        "add-cursus-title-de": "Add Curriculum",
        "close": "close",
        "add": "Add",
        "upload": "upload",
        "all-cursus": "All Curriculum",
        "add-cursus-title-en": "title of the course in  English",
        "add-cursus-title-fr": "title of the course in french",
        "disable-cursus": "disable  curriculum",
        "enable-cursus": "enable  curriculum",

        "menu_item_studies_requetes": "requests",
        "add-requete": "Add requests",

        "all-requete": "All Requests",
        "add-requete-title-fr": "title of the request in french",
        "add-requete-title-en": "title of the request in  English",
        "add-requete-title-de": "title of the request in German",
        "add-material-title-fr": "Title of the subject in French",
        "add-material-title-en": "Title of the subject in English",
        "add-material-title-de": "Title of the material in German",
        "material-field-title-fr-error": "^ Please fill in the title in French",
        "material-field-title-en-error": "^ Please enter the title in English",
        "material-field-title-de-error": "^ Please enter the title in German",
        "material-title": "Materials",
        "all-material": "All materials",
        "material-find": "Found materials",
        "add-material": "Add a new material",
        "delete-material": "Delete material",
        "disable-material": "Disable material",
        "enable-material": "Activate material",
        "address": "Address",
        "add_category": 'Add a category',
        "list_category_faq": 'List of faq categories',
        "faq_category": 'Faq categories',
        "all_faq_category": 'All faq categories',
        "faq_description": 'Title',
        "delete_category": 'Delete category',
        "faq_category_find": 'Faq categories found',


        "category": "Category",
        "add_question": 'Add a question',
        "list_question_faq": 'Faq question list',
        "faq_question": 'Faq questions',
        "all_faq_question": 'All faq questions',

        "faq_question_find": 'Faq questions found',
        "delete_question": "Delete question",
        "question_response": "Response",

        "faq_question_title": 'Question',

        "question_fr": "Question in French",
        "response_fr": "Response in French",
        "question_de": "Question in German",
        "response_de": "Response in German",
        "question_en": "Question in English",
        "response_en": "Response in English",

        "loading_category": "Loading categories",
        "select_category": "Please select a category",
        "empty_category": "No category available",

        "add_university_name": "University name",
        "add_university_cost": "Cost of the request",
        "document_traduction": "Translatable documents",
        "university_field_title_fr_error": "^ Please enter the name of the university",
        "university_field_document_traduction_error": "^ Please fill in the translation document",
        "request_cost_error": "^ Please enter the cost of the request",
        "school_level_error": "^ Please enter at least one language level",
        "university_title": "Universities",
        "all_universities": "All Universities",
        "university_find": "Universities found",
        "add_university": "Add a new university",
        "delete_university": "Delete university",
        "disable_university": "Disable university ",
        "enable_university": "Activate university",
        "add_university_cursus_info": "Add subjects to this course",
        "info-field-address-error": "Please add a valid address",
        "info-field-phone-error": "Please add a valid phone number",
        "info-field-facebook-error": "please enter a valid facebook account",
        "info-field-whatsapp-error": "please enter a valid whatsapp account",
        "user_profile": "Profile",
        "error_profile": "We were unable to update your avatar!",
        "Connexion": "Connection",
        "Actual_Password": "Current Password",
        "New_Password": "New Password",
        "Profile": "Profile",
        "firstname": "First name",
        "lastname": "last name",
        "country": "Country",
        "birthday": "Birthday",
        "residence_place": "Place of residence",
        "nationality": "Nationality",
        "how_understand_about_us": "How to understand us",
        "kind": "Kind",
        "woman": "Woman",
        "man": "Man",
        "save": "Save",
        "purchase": "Purchase",
        "record": "Record",
        "found": "found",
        "page": "Page",
        "of": "of",
        "operator": "Operator",
        "user": "Users",
        "services_order": "Services Order",
        "price": "Price",
        "payment_method": "Means of payment",
        "next": "Next",
        "previous": "Previous",
        "pre_registration_date": "Pre-registration date",
        "pre_registration_date_start": "Start",
        "pre_registration_date_end": "End",
        "date_validator": "Please complete all fields",
        "dates_error": "The end date is less than or equal to the start date",
        "delete_preregistration_date": "Delete this from pre-registration date?",
        "explanation_document": "Explanation document",
        "description_explanation_document": "Document explaining the steps to follow to register in this establishment.",
        "upload_file": "import the file",
        "change_file": "Change file",
        "description_fr": "Description in French",
        "description_en": "Description in English",
        "description_de": "Description in German",
        "name_bank": "Name of the bank",


        "products": "Products",
        "choose_service": "Choose this service",
        "add_advantage_title_fr": "Title of the advantage in French",
        "add_advantage_title_en": "Title of the advantage in English",
        "add_advantage_title_de": "Benefit title in German",
        "advantage_field_title_fr_error": "^ Please enter the title in French",
        "advantage_field_title_en_error": "^ Please enter the title in English",
        "advantage_field_title_de_error": "^ Please enter the title in German",
        "delete_advantage": "Delete this advantage ?",

        "assurance_maladie": "Health insurance",
        "responsabilite_civile": "Liability insurance",
        "subscription_guide_file": "Subscription guide",
        "consult": "Consult",
        "page_university_info_id": "Identifier",
        "page_university_chosed_number": "Number of selection",
        "menu_item_stats": "Statistics",
        "page_statistics_services_title": "Service statistics",
        "page_statistics_services_bank_title": "Number of selection / Bank",
        "page_statistics_services_assurance_title": "Number of selection / Insurance",
        "page_statistics_services_translator_title": "Number of selection / Translator",
        "page_statistics_show_all_btn": "Show all",
        "page_statistics_studies_title": "Choice statistics for study requests",
        "page_statistics_studies_university_title": "Number of selection / University",
        "page_statistics_studies_language_level_title": "Number of selection / Language level",
        "page_statistics_studies_subjects_title": "Number of selection / Subject",
        "page_statistics_studies_curriculum_title": "Number of selection / Course",
        "page_statistics_studies_semester_title": "Number of selection / Semester",

        "STUDIE": "STUDY",
        "JOB": "WORK",
        "TRAINING": "TRAINING",
        "AUPAIR": "AU PAIR",
        "page_request_detail_label_university_name": "University",
        "page_request_detail_label_status": "Status",
        "page_request_detail_label_answer": "Reply",
        "page_request_detail_label_cancel": "Cancel",

        "page_requests_status_online": "Status of the online request",
        "page_requests_status_send_germany": "Mail sent to Germany",
        "page_requests_status_arrived_germany": "Mail arrived in Germany",
        "page_requests_status_send_university": "Mail sent to the university",
        "page_requests_status_preinscription_status": "Pre-registration available",
        "page_request_detail_title_field_error": "^ Please enter the title please",
        "page_request_detail_label_title_mail": "Message title",
        "page_request_detail_delete_confirm": "Do you want to delete this request?",
        "page_request_detail_label_card_send_mail": "Send an e-mail",
        "page_request_detail_status": "Status",

        "page_request_filter_option_clear": "Clear",
        "page_request_filter_option_apply": "Filter",

        "page_request_show_filter": "Filters",
        "page_request_show_group_student_label": "Student",
        "page_request_show_student_label_email": "E-mail",
        "page_request_show_student_label_name": "Name",
        "page_request_show_student_label_sex": "Sex",

        "page_request_show_group_request_label": "Request",
        "page_request_show_request_label_type": "Type",
        "page_request_show_request_label_date_range": "Date range",
        "page_request_show_request_label_status": "Status",

        'request_status_accepted': 'Accepted',
        'request_status_reject': 'Denied',
        'request_status_in_wait': 'Pending',
        'request_status_in_end': 'Completed',
        'request_status_in_pendding': 'Not finished',
        'request_status_yes': 'Yes',
        'request_status_no': 'No',
        'request_button_guide': 'Get started',
        "insurance-field-name-error": "^ Please provide your full insurance name",
        "cursus_title": "cursus",
        "enable_user": "Activate user account",
        "disable_user": "Deactivate user account",
        "delete_user": "Delete user",
        "list_users": "List of users",
        "empty-field-string": "^ Please complete this field",
        "empty-field-email": "^ Please enter a correct email address",
        "add_user": "Add user",
        "role": "Role",
        "phonenumber": "Telephone number",
        "admin": "Administrator",
        "contact_role": "Contact and role",
        "sub_admin": "Sub-Administrator",
        "all-translator": "All translators",
        "User": "User",
        "list-banks": "List of banks",
        "empty-field-price": "^ Please provide a price",
        "name": "Name",
        "delete-bank": "Delete bank",
        "confirmation": "Confirmation",
        "description": "Description",
        "banks": "Banks",
        "personnel_info": "Personal information",
        "change_your_avatar": "change my avatar",

        "Detail": "Detail",
        "Name": "Name",
        "Edition": "Edition",
        "Update": "Update",
        "Password": "Password",
        "load_category": "Load categories",
        "date_error": "The end date is less than or equal to the start date",
        "liability_civile": "liability insurance",
        "check-connexion": "Check your Internet connection",
        "personal_info": "Personal information",
        "change_your_avatar ": "change my avatar",

        "desable-testament": "disable testimony",
        "supprimer-témoignage": "suppress testimony",
        "tout témoignage": "All Testimony",
        "nom_complet ": "Full_name",
        "commentaire": "Comment",
        "Témoignage.": "Testimony.",
        "profil": "Profile.",
        "Appliquer des filtres": "Apply a filter",
        "montant de la demande": "Number of claims",
        "adress": "address",
        "Détail": "Detail",

        "nom_complet": "Full_name",
        " nom_complet ": "Full_name",
        "Témoignage": "Testimonial.",

        "page_product_form_edit_real_price_label": "Actual cost",
        "page_index_request_by_sex": "Requests by sex",
        "page_index_request_by_month": "Number of requests per month",
        "page_index_request_by_month_this_year": "This year",
        "page_index_request_by_month_last_year": "Previous year",
        "page_index_request_by_month_JAN": "JAN",
        "page_index_request_by_month_FEB": "FEB",
        "page_index_request_by_month_MAR": "MAR",
        "page_index_request_by_month_APR": "APR",
        "page_index_request_by_month_MAY": "MAY",
        "page_index_request_by_month_JUN": "JUN",
        "page_index_request_by_month_JUL": "JUL",
        "page_index_request_by_month_AUG": "AUG",
        "page_index_request_by_month_SEP": "SEP",
        "page_index_request_by_month_OCT": "OCT",
        "page_index_request_by_month_NOV": "NOV",
        "page_index_request_by_month_DEC": "DEC",
        "page_index_title": "Statistics overview",
        "page_index_orders_amount": "Order amount",
        "page_index_orders_number": "Order number",
        "page_index_registrations": "Number of registrations",
        "page_index_requests": "Request number",

        "page_product_label_bonus": "Bonus ",

        "page_studies_level_table_header_priority": "Priority",
        "page_studies_level_form_error_empty_field_priority": "^Please fill in the priority",

        "_mail": 'Mail',
        "_send_mail": "Send mail",
        "mail_find": 'mail found',
        "delete_mail": 'Delete mail',
        "_delete_mail": 'do you want to delete the mail from',
        "all_mail": 'All mails',
        "_avatar": "avatar",
        "_name_and_surname": "name and surname",
        "_object": "object",
        "_content": "content",
        "_mail_option": "option",
        "_mail_action": "action",
        "_message": "message",
        "TO_EVERYONE": "send to everyone",
        "SERVICE_PAID": "send to those who have already paid for a service",
        "NOT_SERVICE_PAID": "send to those who have never paid for a service",
        "SEND_REQUEST": "send to those who have already submitted a request",
        "NOT_SEND_REQUEST": "send to those who have never submitted a request",
        'global_action_enable': 'Activate',
        'global_action_disable': 'Disable',
        'testimony_dialog_activate': 'Activate this testimony?',
        'testimony_dialog_disable': 'Deactivate this testimony?',
        'testimony_info_char_max_number': 'For a better rendering, the comment must not exceed 200 characters',
        "page_studies_level_form_error_leer_field_priority": "^ Please indicate the priority",
        'user-find': 'users found',

        'date_send': "date of receipt"
    }
};