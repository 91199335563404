export default {
    translation: {

        "Contenu_Guide_Voyage": "Reiseführer Inhalt",
        "Updated": "Aktualisiert",
        "Emploi": "Beschäftigung",
        "Formation": "Schulung",
        "Etude": "Studie",

        "service": "Bedienung",
        "request_number": "Anzahl zusätzlicher Anfragen",
        "add_request_label": "Zusätzliche Anfrage hinzufügen",
        "all_travelguide": "Alle Reiseführer",
        "Learn_more": "Erfahren Sie mehr",
        "title_fr": "Titel in Französisch",
        "title_en": " Titel in Englisch",
        "title_de": "titel in deutsch",
        "content_fr": "Inhalt in Französisch",
        "content_en": "Inhalt in Französisch",
        "content_de": "Inhalt in Englisch",
        "delete-guidevoyage": "löschen Reiseführer ",
        "guidevoyage_find": "Reiseführer findet",
        "add-guidevoyage": "Fügen Sie einen Reiseführer hinzu",
        "guide_voyage_title": "Reiseführer",
        "menu_item_guide_voyage": "Reiseführer",
        "enable-testimony": "Aktivieren Sie das Zeugnis",
        "desable-testimony": "Deaktivieren Sie das Zeugnis",
        "delete-testimony": "Zeugnis löschen",
        "Image et nom": "Bild und Name",
        "add-testimony": "Fügen Sie einen Zeugnis",
        "all-testimony": "Alle Zeugnis",
        "complete_name": "vollständiger Name",
        "commentary": "Kommentar",
        "date": " Datum kommentare",
        "profession": "Beruf",
        "testimony_find": "Zeugnis findet",
        "all_testimony": "Alle zeugnis",
        "testimony_title": "Zeugnis",
        "Testimony": "Zeugnis",
        "profile": "Profil",
        "Image": "Bild",
        "matiere et semestre": " Angelegenheiten & semester",
        "all_Request": "alle anwendung",
        "add_demandes": " anwendung",
        "menu_item_request": "anwendung",
        "yes": "ja",
        "no": "nein",
        "curriculum_title": "Anwendung",
        "curriculum": "kurse",
        "public_id": "login",
        "date_added": "Datum der Anfrage",
        "add_request_name": "Name der Anfrage",
        "request_find": "Anfrage gefunden",
        "request_type": "Anfragetyp",
        "pour": "für",
        "menu_item_dashboard": "Dashboard",
        "menu_item_studies": "Studies",
        "menu_item_studies_university": "Universitäten",
        "menu_item_studies_materials": "Materials",
        "menu_item_studies_curriculum": "Kurs",
        "menu_item_studies_semester": "Semester",
        "menu_item_banks": "Banks",
        "page_bank_infos_all_bank": "Alle Banken",
        "page_bank_infos__banks_find": "Banks found",
        "activate-bank": "Bank aktivieren",
        "disable-bank": "Bank deaktivieren",
        "add-bank": "Bank hinzufügen",
        "menu_item_assurances": "Versicherung",
        "menu_item_translators": "Übersetzer",
        "menu_item_messages": "Messages",
        "menu_item_presentations": "Inhalt",
        "menu_item_presentations_faq_categories": "FAQ zu Kategorien",
        "menu_item_presentations_faq_questions": "FAQ-Fragen",
        "menu_item_requests": "Anfragen",
        "menu_item_orders": "Käufe",
        "menu_item_services": "Services",
        "menu_item_presentations_infos": "Nachrichten",
        "menu_item_products": "Produkte",
        "welcome": 'Gesundeutch',
        "Detail": "Detail",
        "Name": "Name",
        "email": "Email",
        "address": "Adresse",
        "translator": "Übersetzer",
        "link": "Link",
        "delete-message": "Nachricht löschen",
        "delete-translator": "Übersetzer löschen",
        "object": "Objekt",
        "author": "Autor",
        "error-file-type": "Sie müssen ein Bild im JPG- oder PNG-Format importieren",
        "delete": "Löschen",
        "see": "sehen",
        "actions": "Aktionen",
        "message": "Nachricht",
        "messages": "Nachrichten",
        "close": "schließen",
        "send": "Senden Sie ",
        "Edition": "Edition",
        "add-translator": "Hinzufügen eines Übersetzers",
        "Update": "Update",
        "addcomplete_name": "Vollständiger Name",
        "connect-to-admin": "Verbindung zum Administrationsbereich herstellen",
        "connect": "Connect",
        "Password": "Passwort",
        "phone_number": "Telefonnummer",
        "translator_language": "Übersetzersprache",
        "response": "Antwort",
        "responses": "Antworten",
        "all-message": "Alle Nachrichten",
        "list-message": "Liste der Nachrichten",
        "keyword": "Suche...",
        "translator-field-link-error": "^ Bitte geben Sie einen Link an",
        "translator-field-language-error": "^ Bitte geben Sie Ihre Übersetzersprache an",
        "translator-field-name-error": "^ Bitte geben Sie Ihren vollständigen Übersetzernamen an",
        "translator-field-phone_number-error": "^ Bitte geben Sie Ihre Übersetzertelefonnummer an",
        "translator-field-email-error": "^ Bitte geben Sie die E-Mail-Adresse des Übersetzers ein",
        "empty_field_phone_number": "^ Please add a phone number",
        "edit": "Edit",
        "message-find": "Nachrichten gefunden",
        "translator-find": "Übersetzer gefunden",
        "disconnect": "trennen",
        "any-response": "Noch keine Antwort.",
        "forward-for": "Antworten für",
        "activate-translator": "Übersetzer aktivieren",
        "activate": "Aktivieren",
        "disable-translator": "Übersetzer deaktivieren",
        "disable": "Deaktivieren",
        "check-connection": "Überprüfen Sie Ihre Internetverbindung",
        "confirm-delete": "Löschen bestätigen",
        "insurances": "Versicherung",
        "insurance": "Versicherung",
        "insurance-description": "Beschreibung",
        "add-insurance": "Versicherung hinzufügen",
        "delete-insurance": "Versicherung löschen",
        "enable-insurance": "Versicherung aktivieren",
        "disable-insurance": "Invalidenversicherung",
        "all-insurances": "Alle Versicherungen",
        "list-insurance": "Liste der Versicherungen",
        "error-field-name-error": "^ Bitte geben Sie Ihren vollständigen Versicherungsnamen an",
        "insurance-field-description-fr-error": "^ Bitte füllen Sie die Beschreibung auf Französisch aus",
        "insurance-field-description-en-error": "^ Bitte geben Sie die Beschreibung in Englisch ein",
        "insurance-field-description-de-error": "^ Bitte geben Sie die Beschreibung auf Deutsch ein",
        "insurance-field-link-error": "^ Bitte geben Sie einen Link an",
        "add-insurance-name": "Vollständiger Name",
        "add-description-fr": "Beschreibung auf Französisch",
        "add-description-en": "Beschreibung in Englisch",
        "add-description-de": "Beschreibung auf Deutsch",
        "add-insurance-link": "Link",
        "add-insurance-price": "Preis",
        "insurance-price-error": "^ Bitte geben Sie den Preis für diese Versicherung ein",
        "insurance-find": "Versicherung gefunden",
        "menu_item_studies_niveaux_de_langues": "Sprachniveaus",
        "add-schoolLevel-title-fr": "Titre du niveau en français",
        "add-schoolLevel-title-en": "Titel des Levels in Englisch",
        "add-schoolLevel-title-de": "Titel der Stufe in Deutsch",
        "schoolLevel-field-title-fr-error": "^ Bitte geben Sie den Titel auf Französisch ein",
        "schoolLevel-field-title-en-error": "^ Bitte geben Sie den Titel in Englisch ein",
        "schoolLevel-field-title-de-error": "^ Bitte geben Sie den Titel auf Deutsch ein",
        "title": "Titel",
        "school-level-title": "Sprachniveaus",
        "all-school-level": "Alle Level",
        "school-level-find": "gefundene stufen",
        "add-school-level": "Sprachstufe hinzufügen",
        "delete-school-level": "Stufe löschen",
        "disable-school-level": "Deaktivierungsstufe",
        "enable-school-level": "Aktiviere das Level",
        "add-semester-title-fr": "Titel des Semesters in Französisch",
        "add-semester-title-en": "Titel des Semesters in englischer Sprache",
        "add-semester-title-de": "Titel des Semesters in deutscher Sprache",
        "semester-field-title-fr-error": "^ Bitte geben Sie den Titel auf Französisch ein",
        "semester-field-title-en-error": "^ Bitte geben Sie den Titel in Englisch ein",
        "semester-field-title-de-error": "^ Bitte geben Sie den Titel auf Deutsch ein",
        "semester-title": "Semester",
        "all-semester": "Alle Semester",
        "semester-find": "Semester gefunden",
        "add-semester": "Neues Semester hinzufügen",
        "delete-semester": "Semester löschen",
        "disable-semester": "Semester deaktivieren",
        "enable-semester": "Aktiviere das Semester",

        "add-material-title-fr": "Titel des Faches auf Französisch",
        "add-material-title-en": "Titel des Faches in Englisch",
        "add-material-title-de": "Titel des Materials in deutscher Sprache",
        "material-field-title-fr-error": "^ Bitte geben Sie den Titel auf Französisch ein",
        "material-field-title-en-error": "^ Bitte geben Sie den Titel in Englisch ein",
        "material-field-title-de-error": "^ Bitte geben Sie den Titel auf Deutsch ein",
        "material-title": "Materialien",
        "all-material": "Alle Materialien",
        "material-find": "Gefundene Materialien",
        "add-material": "Neues Material hinzufügen",
        "delete-material": "Material löschen",
        "disable-material": "Material deaktivieren",
        "enable-material": "Material aktivieren",
        "page_university_preregistration_link": "Vorregistrierungslink",
        "category": "Kategorie",
        "add_category": 'Kategorie hinzufügen',
        "list_category_faq": 'Liste der FAQ-Kategorien',
        "faq_category": 'FAQ-Kategorien',
        "all_faq_category": 'Alle FAQ-Kategorien',
        "faq_description": 'Titel',
        "delete_category": 'Kategorie löschen',
        "faq_category_find": 'FAQ-Kategorien gefunden',
        'menu_item_cursus': 'Lehrplan',
        "add-cursus": "Kurs hinzufügen",
        "reload": "Aufladen",
        "edition": "Bearbeiden",
        "add-cursus-title-de": "Fügen Sie den Lehrplan hinzu",
        "add": "Hinzufugen",
        "all-cursus": "Alle Kurse",
        "add-cursus-title-en": "Titel des Kurses in Englisch",
        "add-cursus-title-fr": "Titel des Kurses in Französisch",
        "disable-cursus": "disable  kurse",
        "enable-cursus": "activate  kurse",
        "description_fr": "Beschreibung auf Französisch",
        "description_en": "Description in English",
        "description_de": "Beschreibung auf Deutsch",
        "name_bank": "Name der Bank",


        "menu_item_studies_requetes": "Petition",
        "cursus-title": "Petition",
        "add-requete": "Petition hinzufügen",
        "upload": "Hinzufugen",
        "all-requete": "Alle Petition ",
        "add-requete-title-fr": "Titel des Petition in Französisch",
        "add-requete-title-en": "Titel des Petition in Englisch",
        "add-requete-title-de": "Titel des Petition in deutscher Sprache",
        "add_question": 'Frage hinzufügen',
        "list_question_faq": 'FAQ-Fragenliste',
        "faq_question": 'FAQ-Fragen',
        "all_faq_question": 'Alle FAQ-Fragen',

        "faq_question_find": 'FAQ-Fragen gefunden',
        "delete_question": "Frage löschen",
        "question_response": "Response",

        "faq_question_title": 'Frage',

        "question_fr": "Frage auf Französisch",
        "response_fr": "Antwort auf Französisch",
        "question_de": "Frage auf Deutsch",
        "response_de": "Antwort auf Deutsch",
        "question_en": "Frage auf Englisch",
        "response_en": "Antwort auf Englisch",

        "load_category": "Laden von Kategorien",
        "select_category": "Bitte wählen Sie eine Kategorie aus",
        "empty_category": "Keine Kategorie verfügbar",

        'menu_item_users': 'Benutzer',
        "list-translator": "Liste der Übersetzer",

        "add_university_name": "Universitätsname",
        "add_university_cost": "Kosten der Anfrage",
        "document_traduction": "Übersetzbare Dokumente",
        "university_field_title_fr_error": "^ Bitte geben Sie den Namen der Universität ein",
        "university_field_document_traduction_error": "^ Bitte füllen Sie das Übersetzungsdokument aus",
        "request_cost_error": "^ Bitte geben Sie die Kosten der Anfrage ein",
        "school_level_error": "^ Bitte geben Sie mindestens eine Sprachstufe ein",
        "university_title": "Universitäten",
        "all_universities": "All Universities",
        "university_find": "Universitäten gefunden",
        "add_university": "Neue Universität hinzufügen",
        "delete_university": "Universität löschen",
        "disable_university": "Universität deaktivieren ",
        "enable_university": "Universität aktivieren",
        "add_university_cursus_info": "Fächer zu diesem Kurs hinzufügen",
        "info-field-address-error": "Bitte geben Sie eine gültige Adresse an",
        "info-field-phone-error": "Bitte fügen Sie eine gültige Telefonnummer hinzu",
        "info-field-facebook-error": "Bitte geben Sie ein gültiges Facebook-Konto ein",
        "info-field-whatsapp-error": "Bitte geben Sie ein gültiges WhatsApp-Konto ein",
        "user_profile": "Profil",
        "change_your_avatar": "changer mon avatar",
        "error_profile": "Wir konnten Ihren Avatar nicht aktualisieren!",
        "Connexion": "Verbindung",
        "Actual_Password": "Aktuelles Passwort",
        "New_Password": "Neues Passwort",
        "Profile": "Profil",
        "firstname": "Vorname",
        "lastname": "Name",
        "country": "Land",
        "birthday": "Geburtstag",
        "residence_place": "Wohnort",
        "nationality": "Nationalität",
        "whatsapp": "WhatsApp",
        "how_understand_about_us": "Wie man uns versteht",
        "kind": "Art",
        "woman": "Frau",
        "man": "Mann",
        "save": "Speichern",
        "purchase": "Kauf",
        "record": "Datensätze",
        "found": "gefunden",
        "page": "Seite",
        "of": "von",
        "operator": "Betreiber",
        "user": "Benutzer",
        "services_order": "Serviceauftrag",
        "price": "Preis",
        "payment_method": "Zahlungsmethode",
        "next": "folgende",
        "previous": "früher",
        "pre_registration_date": "Vorregistrierungsdatum",
        "pre_registration_date_start": "Start",
        "pre_registration_date_end": "End",
        "date_validator": "Bitte alle Felder ausfüllen",
        "date_error": "Das Enddatum ist kleiner oder gleich dem Startdatum",
        "delete_preregistration_date": "Dies vom Vorregistrierungsdatum löschen?",
        "explanation_document": "erklärungsdokument",
        "description_explanation_document": "Dokument, in dem die Schritte zur Registrierung in dieser Einrichtung erläutert werden",
        "upload_file": "Datei importieren",
        "change_file": "Datei ändern",

        "products": "Produkte",
        "choose_service": "Wählen Sie diesen Service",
        "add_advantage_title_fr": "Titel des Vorteils auf Französisch",
        "add_advantage_title_en": "Titel des Vorteils in Englisch",
        "add_advantage_title_de": "Benefit title in Deutsch",
        "advantage_field_title_fr_error": " ^ Bitte geben Sie den Titel auf Französisch ein ",
        "advantage_field_title_en_error": " ^ Bitte geben Sie den Titel in englischer Sprache ein ",
        "advantage_field_title_de_error": " ^ Bitte geben Sie den Titel auf Deutsch ein ",
        "delete_advantage": "Diesen Vorteil löschen?",

        "assurance_maladie": "Krankenversicherung",
        "liability_civile": "haftpflichtversicherung",
        "subscription_guide_file": "Subscription Guide",
        "consult": "konsultieren",
        "page_university_info_id": "Identifier",
        "page_university_chosed_number": "Anzahl der Auswahl",
        "menu_item_stats": "Statistik",
        "page_statistics_services_title": "Servicestatistik",
        "page_statistics_services_bank_title": "Nummer der Auswahl / Bank",
        "page_statistics_services_assurance_title": "Anzahl der Auswahl / Versicherung",
        "page_statistics_services_translator_title": "Anzahl der Auswahl / Übersetzer",
        "page_statistics_show_all_btn": "Alle anzeigen",
        "page_statistics_studies_title": "Auswahlstatistik für Studienanfragen",
        "page_statistics_studies_university_title": "Anzahl der Auswahl / Universität",
        "page_statistics_studies_language_level_title": "Anzahl der Auswahl / Sprachstufe",
        "page_statistics_studies_subjects_title": "Anzahl der Auswahl / Betreff",
        "page_statistics_studies_curriculum_title": "Anzahl der Auswahl / Kurs",
        "page_statistics_studies_semester_title": "Anzahl der Auswahl / Semester",


        "STUDIE": "STUDIEREN",
        "JOB": "ARBEITEN",
        "TRAINING": "AUSBILDUNG",
        "AUPAIR": "AU PAIR",
        "page_request_detail_label_university_name": "Universität",
        "page_request_detail_label_status": "Status",
        "page_request_detail_label_answer": "Reply",
        "page_request_detail_label_cancel": "Cancel",

        "page_requests_status_online": "Status der Online-Anfrage",
        "page_requests_status_send_germany": "Mail nach Deutschland gesendet",
        "page_requests_status_arrived_germany": "Mail in Deutschland angekommen",
        "page_requests_status_send_university": "Mail an die Universität gesendet",
        "page_requests_status_preinscription_status": "Vorregistrierung verfügbar",
        "page_request_detail_title_field_error": "^ Bitte geben Sie den Titel ein",
        "page_request_detail_label_title_mail": "Nachrichtentitel",
        "page_request_detail_delete_confirm": "Möchten Sie diese Anfrage löschen?",
        "page_request_detail_label_card_send_mail": "E-Mail senden",
        "page_request_detail_status": "Status",

        "page_request_filter_option_clear": "Clear",
        "page_request_filter_option_apply": "Filter",

        "page_request_show_filter": "Filter",
        "page_request_show_group_student_label": "Student",
        "page_request_show_student_label_email": "E-Mail",
        "page_request_show_student_label_name": "Name",
        "page_request_show_student_label_sex": "Sex",

        "page_request_show_group_request_label": "Request",
        "page_request_show_request_label_type": "Type",
        "page_request_show_request_label_date_range": "Datumsbereich",
        "page_request_show_request_label_status": "Status",

        'request_status_accepted': 'Accepted',
        'request_status_reject': 'Verweigert',
        'request_status_in_wait': 'Ausstehend',
        'request_status_in_end': 'Abgeschlossen',
        'request_status_in_pendding': 'Nicht fertig',
        'request_status_yes': 'Ja',
        'request_status_no': 'Nein',
        'request_button_guide': 'Erste Schritte',

        "detail": "Ausschnitt",
        "nom": "Name",
        "update": "Aktualisieren",
        "password": "Passwort",
        "preview": "Vorherige",
        "translators": "Übersetzer",
        "check-connexion": "Überprüfen Sie Ihre Internetverbindung",
        "insurance-field-name-error": "^Bitte geben Sie Ihren vollständigen Namen der Versicherung an",
        "cursus_title": "cursus",
        "loading_category": "Kategorie laden",
        "enable_user": "Benutzerkonto aktivieren",
        "disable_user": "Benutzerkonto deaktivieren",
        "delete_user": "Benutzer löschen",
        "list_users": "Liste der Benutzer",
        "empty-field-string": "^Bitte füllen Sie dieses Feld aus.^",
        "empty-field-email": "^^Bitte geben Sie eine korrekte E-Mail-Adresse ein",
        "add_user": "Einen Benutzer hinzufügen",
        "role": "Rolle",
        "phonenumber": "Telefonnummer",
        "admin": "Verwalter",
        "contact_role": "Kontakt und Rolle",
        "sub_admin": "Unter_Administrator",
        "all-translator": "All-Übersetzer",
        "User": "Benutzer",
        "list-banks": "Liste der Banken",
        "empty-field-price": "Bitte nennen Sie einen Preis.",
        "name": "Name",
        "delete-bank": "Bank löschen",
        "confirmation": "Bestätigung",
        "description": "Beschreibung",
        "banks": "Banken",
        "personal_info": "Persönliche Informationen",
        "dates_error": "Das Enddatum ist kleiner oder gleich dem Startdatum",
        "responsabilite_civile": "Zivil_Haftpflichtversicherung",
        "change_your_avatar ": "Ändere meinen Avatar",

        "Apply filters": "Filter anwenden",
        "filter": "filtern",
        "requestStatus": "Status der Anfrage",
        "etiquette de requete": " Anfrage-Tag",
        "request amount": "Nummer des Antrags",
        "ended": "beendet",
        "onGoing": "weitergehend",
        "completed": "abgeschlossen",
        "customerName": "Kundenname",

        "personnel_info": "Persönliche Informationen",
        "desable-testament": "verwerfliches Zeugnis",
        "supprimer-témoignage": "Zeugenaussage unterdrücken",
        "tout témoignage": "Alle Zeugen",
        "nom_complet": "Vollständiger_Name",
        "commentaire": "Kommentar",
        "Témoignage.": "Zeugnis.",
        "profil": "Profil",
        "Appliquer des filtres": "Einen Filter anwenden",
        "montant de la demande": "Anzahl der Ansprüche",
        "adress": "Adresse",

        "nom_complet ": "Vollständiger_Name",
        "Détail": "Detail.",
        " nom_complet ": "Vollständiger_Name",
        "Témoignage": "Zeugnis.",

        "page_product_form_edit_real_price_label": "Tatsächliche Kosten",
        "page_index_request_by_sex": "Anfragen nach Geschlecht",
        "page_index_request_by_month": "Anzahl der Anfragen pro Monat",
        "page_index_request_by_month_this_year": "Dieses Jahr",
        "page_index_request_by_month_last_year": "Vorjahr",
        "page_index_request_by_month_JAN": "JAN",
        "page_index_request_by_month_FEB": "FEB",
        "page_index_request_by_month_MAR": "März",
        "page_index_request_by_month_APR": "AVR",
        "page_index_request_by_month_MAY": "MAI",
        "page_index_request_by_month_JUN": "JUN",
        "page_index_request_by_month_JUL": "JUL",
        "page_index_request_by_month_AUG": "AUG",
        "page_index_request_by_month_SEP": "SEP",
        "page_index_request_by_month_OCT": "OKT",
        "page_index_request_by_month_NOV": "NOV",
        "page_index_request_by_month_DEC": "DEZ",
        "page_index_title": "Statistikübersicht",
        "page_index_orders_amount": "Bestellbetrag",
        "page_index_orders_number": "Bestellnummer",
        "page_index_registrations": "Anzahl der Registrierungen",
        "page_index_requests": "Anforderungsnummer",

        "page_product_label_bonus": "Bonus ",

        "page_studies_level_table_header_priority": "Priorität",
        "page_studies_level_form_error_leer_field_priority": "^Bitte geben Sie die Priorität an",

        "_mail": 'Mail',
        "_send_mail": "Mail senden",
        "mail_find": 'mail gefunden',
        "delete_mail": 'Mail löschen',
        "_delete_mail": 'Möchten Sie die Mail löschen von',
        "all_mail": 'Alle Mails',
        "_avatar": "Avatar",
        "_name_and_surname": "Vor- und Nachname",
        "_object": "object",
        "_content": "content",
        "_mail_option": "option",
        "_mail_action": "action",
        "_message": "message",
        "TO_EVERYONE": "an alle senden",
        "SERVICE_PAID": "an diejenigen senden, die bereits für eine Dienstleistung bezahlt haben",
        "NOT_SERVICE_PAID": "an diejenigen senden, die noch nie für eine Dienstleistung bezahlt haben",
        "SEND_REQUEST": "an diejenigen senden, die bereits eine Anfrage eingereicht haben",
        "NOT_SEND_REQUEST": "an diejenigen senden, die noch nie eine Anfrage eingereicht haben",
        'global_action_enable': 'Aktivieren',
        'global_action_disable': 'Deaktivieren',
        'testimony_dialog_activate': 'Dieses Zeugnis aktivieren?',
        'testimony_dialog_disable': 'Dieses Zeugnis deaktivieren?',
        'testimony_info_char_max_number': 'Für ein besseres Rendering darf der Kommentar 200 Zeichen nicht überschreiten',


        'user-find': 'Benutzer gefunden',
        'date_send': "Eingangsdatum"
    }
};